:root {
  --darker-blue: #223365;
  --dark-blue: #445588;
  --blue: #9099b2;
  --gray: #adacac;
  --light-gray: #d6d6d6;
  --lighter-gray: #f8f6f6;
}

.App {
  text-align: center;
  margin: auto;
  width: 100%;
  max-width: 414px;
  background-color: white;
}

@media only screen and (min-width: 415px) {
  .App {
    border-top: 80px solid;
    border-right: 10px solid;
    border-bottom: 80px solid;
    border-left: 10px solid;
    border-radius: 50px;
    margin: 20px auto;
    height: 760px;
    max-width: 431px;
    overflow-y: auto;
  }

  ::-webkit-scrollbar {
    display: none;
  }
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

.test {
  background-color: white;
}

.header-container {
  position: fixed;
  width: 100%;
  max-width: 411px;
  z-index: 2;
}

@media only screen and (min-width: 415px) {
  .header-container {
    position: absolute;
  }
}

.location-header {
  height: 70px;
  background-color: var(--darker-blue);
  padding-top: 10px;
  color: white;
  border-bottom: 2px solid black;
}

.location-header-text {
  margin: auto;
  vertical-align: middle;
  font-size: 1.15em;
  font-weight: 600;
}

.location-header-arrow {
  color: white;
  position: absolute;
  margin: 10px;
}

.location-header-bars {
  color: white;
  position: absolute;
  margin: 10px;
  right: 10px;
  top: 5px;
}

.location-list-wrapper {
  padding-top: 132px;
}

.location-list-wrapper-destination {
  padding-top: 167px;
}

.location-list {
  width: 100%;
  text-align: left;
  font-size: .9em;
}

.location-list a {
  text-decoration: none;
  color: black;
}

.location-each {
  margin: 0 15px;
  border-bottom: 1px solid var(--light-gray);
  padding: 11px 0 11px 5px;
}

.search-wrapper {
  background-color: var(--darker-blue);
  padding: 6px;
}

.search-wrapper2 {
  border-radius: 10px;
  background-color: var(--dark-blue);
  margin: 5px;
  text-align: left;
}

.location-search-icon {
  margin: 0 10px 0 10px;
}

.location-search-box {
  height: 35px;
  width: 80%;
  background-color: transparent;
  border: 0px solid;
  font-size: 15px;
  text-align: left;
  color: white;
}

.location-search-box:focus {
  outline: 1px solid var(--dark-blue);
}

.location-search-box::-webkit-input-placeholder {
  color: #8b9dc3 !important;
}

.alpha {
  background-color: var(--lighter-gray);
  color: var(--darker-blue);
  text-align: left;
}

.blank {
  background-color: var(--darker-blue);
  height: 30px;
}

.letter {
  margin: 0 10px;
  padding-top: 2px;
  font-size: 16px;
}

.black-bar {
  background-color: #011f4b;
  height: 6px;
}

.yellow-progress {
  height: 100%;
  background-color: orange;
}

.origin-destination {
  background-color: var(--darker-blue);
  color: white;
  text-align: left;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

.origin-destination-third {
  display: inline-block;
  vertical-align: middle;
  font-weight: 300;
  font-size: .9em;
}

.origin-destination-middle {
  padding: 0 10px;
}

.origin-destination-next {
  display: inline-block;
  margin-right: 5px;
}

#arrow-right {
  color: #3b5998;
}

.location-arrow {
  /*border: 1px solid red;*/
  float: right;
  color: orange;
  font-size: 1.5em;
}

.timeclock-main {
  margin: 5px 0;
  width: 100%;
  position: absolute;
  z-index: 2;
}

.timeclock-text {
  margin-top: 5vw;
  font-size: 8vw;
  color: white;
  text-shadow: 3px 3px 5px rgba(25, 25, 25, 1),
    -2px -2px 5px rgba(25, 25, 25, 1), 2px -2px 5px rgba(25, 25, 25, 1),
    -2px 2px 5px rgba(25, 25, 25, 1), 2px 2px 5px rgba(25, 25, 25, 1);
  position: relative;
  -webkit-animation-name: example;
          animation-name: example;
  -webkit-animation-duration: 8s;
          animation-duration: 8s;
  -webkit-animation-iteration-count: 100;
          animation-iteration-count: 100;
  max-width: 250px;
}

@media only screen and (min-width: 415px) {
  .timeclock-text {
    font-size: 2.2em;
    margin-top: 20px;
  }

  .timeclock-main {
    width: 400px;
    max-width: 400px;
  }
}

.timeclock-time {
  font-size: 25px;
  letter-spacing: 1px;
  line-height: 0%;
  padding: 0;
  white-space: nowrap;
}

.timeclock-date {
  line-height: 1;
  letter-spacing: 2px;
  margin: 0;
  padding: 0;
  font-size: 34px;
}

@-webkit-keyframes example {
  0% {
    left: 25px;
  }
  25% {
    left: 65px;
  }
  50% {
    left: 25px;
  }
  75% {
    left: 65px;
  }
  100% {
    left: 25px;
  }
}

.time-block-wrapper {
  border-bottom: 2px solid #c0c5ce;
}

.colorblock-main {
  margin: 10px 0 0 0;
}

.each-block {
  background-color: var(--light-gray);
  width: 30%;
  padding-top: 12%;
  position: relative;
  display: inline-block;

  -webkit-animation-name: block;

          animation-name: block;
  -webkit-animation-duration: 15s;
          animation-duration: 15s;
  -webkit-animation-iteration-count: 100;
          animation-iteration-count: 100;
}

@-webkit-keyframes block {
  0% {
    opacity: 1;
  }
  5% {
    opacity: 0.7;
  }
  10% {
    opacity: 1;
  }
  15% {
    opacity: 0.7;
  }
  20% {
    opacity: 1;
  }
  25% {
    opacity: 0.7;
  }
  30% {
    opacity: 1;
  }
  35% {
    opacity: 0.7;
  }
  40% {
    opacity: 1;
  }
  45% {
    opacity: 0.7;
  }
  50% {
    opacity: 1;
  }
  55% {
    opacity: 0.7;
  }
  60% {
    opacity: 1;
  }
  65% {
    opacity: 0.7;
  }
  70% {
    opacity: 1;
  }
  75% {
    opacity: 0.7;
  }
  80% {
    opacity: 1;
  }
  85% {
    opacity: 0.7;
  }
  90% {
    opacity: 1;
  }
  95% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.block1 {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  /* opacity: 0.5; */
}

.block3 {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.sketch-picker-wrapper {
  /* height: 0px;
  position: fixed;
  top: -80px */
  height: 0;
}

.sketch-picker-box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.sketch-picker {
  z-index: 10;
  position: relative;
  width: 100% !important;
}

.half-circle {
  background-color: var(--dark-blue);
  width: 80px;
  height: 40px;
  border-bottom-left-radius: 60px;
  border-bottom-right-radius: 60px;
  margin: auto;
}

.fa {
  color: white;
  font-size: 40px;
}

.tap-button {
  font-size: 0.9em;
  font-weight: 700;
  /*border: 1px solid blue;*/
  margin-bottom: 5px;
}

.ticket-activated-at {
  background-color: #ed203e;
  font-size: 18px;
  border-radius: 5px;
  color: white;
  margin: 0 15px 5px 15px;
  padding: 7px;
  border-bottom: 2px solid #cc3333;
  font-weight: 600;
}

.ticket-type-info {
  background-color: white;
  font-size: 18px;
  border-bottom: 1px solid #a7adba;
  border-radius: 5px;
  color: black;
  margin: 10px 15px;
  padding: 7px;
  border-bottom: 2px solid light-gray;
  font-weight: 600;
  display: flex;
  justify-content: center;
}

.ticket-train-time {
  background-color: var(--darker-blue);
  width: 50%;
  border-bottom: 1px solid #a7adba;
  border-radius: 5px;
  color: white;
  padding: 10px;
}

.ticket-train-button {
  background-color: var(--darker-blue);
  width: 50%;
  border-bottom: 1px solid #a7adba;
  border-radius: 5px;
  color: white;
  padding: 15px;
  font-size: 1.3rem;
}

.lirr {
  background-color: white;
  font-size: 15px;
  margin: 0 15px;
  font-weight: 600;
  text-align: left;
  padding: 3px 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.ticket-area-code {
  /* background-color: #cdecf5; */
  /* background-image: url("./imgs/mta-01.png"); */
  background-color: #d9ebef;
  margin: 0 15px 15px 15px;
  border-bottom: 2px solid #a7adba;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.mtaLogo-bg {
  position: absolute;
  width: 100%;
  opacity: 0.2;
}

.wht-logo {
  opacity: 0.7;
}

.mtaLogo-bg img {
  width: 130px;
}

.ticket-area-code-wrapper1 {
  display: inline-block;
  text-align: right;
  vertical-align: top;
  width: 70%;
  height: 100%;
  padding: 0 10px 20px 10px;
  z-index: 2;
}

.ticket-area-code-wrapper2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  height: 100%;
}

.ticket-area-code-month {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
}

.ticket-area-code-month-wrapper {
  border: 2px solid black;
  padding: 5px 10px;
  font-size: 26px;
  font-weight: 800;
  z-index: 10;
}

.ticket-area-code-letter {
  line-height: 1.15;
  font-size: 26px;
  font-weight: 600;
}

.ticket-area-code-origindest {
  font-size: 1.8rem;
  font-weight: 800;
  line-height: 2.5;
}

.ticket-area-code-areanum {
  font-size: 2.9rem;
  font-weight: 800;
  padding-right: 10px;
}

.ticket-area-code-circle-top {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-bottom: 100px solid black;
  z-index: 10;
}

.ticket-area-code-circle-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-top: 100px solid black;
  z-index: 10;
}

.ticket-area-code-circle-inner-top {
  color: white;
  font-size: 30px;
  margin-top: 160px;
  font-weight: 600;
}

.ticket-area-code-circle-inner-bottom {
  color: white;
  font-size: 30px;
  margin-top: -160px;
  font-weight: 600;
}

.button-wrapper {
  background-color: blue;
}

.app-container-lower {
  background-color: #f1f1f1;
  height: 100%;
  /* height: 410px; */
  overflow: hidden;
  padding-bottom: 50px;
}

.buy-tickets-wrapper {
  padding-top: 70px;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: var(--darker-blue);
}

@media only screen and (min-width: 415px) {
  .buy-tickets-wrapper {
    max-height: 600px;
    max-width: 411px;
  }
}

.buytickets {
  background-color: var(--darker-blue);
}

.button {
  margin: 10px auto;
  font-size: 20px;
  border: 1px solid black;
  border-radius: 10px;
  margin: 10px;
  padding: 10px 5px;
  color: black;
  text-decoration: none;
  background-color: white;
}

.buy-ticket-button-blue {
  max-height: 74px;
  margin: 10px auto;
  font-size: 16px;
  border: 1px solid black;
  border-radius: 10px;
  margin: 10px;
  margin-top: -8px;
  padding: 10px 5px;
  color: white;
  text-decoration: none;
  background-color: var(--dark-blue);
}

.buy-ticket-button-blue img {
  vertical-align: bottom;
}

.buy-ticket-text {
  display: inline-block;
  text-align: left;
  width: 70%;
}

.buy-ticket-img {
  margin-right: 20px;
  display: inline-block;
  width: 10%;
}

.buy-ticket-img img{
  width: 50px;
}

.buy-ticket-arrow {
  width: 10%;
  color: #3b5998;
  display: inline-block;
  text-align: right;
  font-size: 1em;
}

.location-list-wrapper-ticket {
  /* height: 100vh; */
  padding-top: 115px;
}
.tab-wrapper {
  background-color: var(--dark-blue);
}

.single-tab {
  display: inline-block;
  padding: 10px;
  width: 50%;
  background-color: white;
  margin-top: 5px;
  border-top-right-radius: 8px;
  font-size: .9em;
}

.multiple-tab {
  display: inline-block;
  padding: 10px;
  width: 50%;
  color: white;
  font-weight: 300;
  font-size: .9em;
}

.ticket-list-wrapper {
  background-color: white;
  padding-top: 185px;
}

.ticket-list {
  width: 100%;
  text-align: left;
  background-color: white;
}

.ticket-each {
  margin: 0 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--light-gray);
  padding: 20px 0;
  color: black;
}

.ticket-type {
  display: inline-block;
  width: 70%;
  text-align: left;
}

.ticket-price {
  display: inline-block;
  width: 30%;
  text-align: right;
}

.ticket-arrow {
  padding: 0 10px;
  color: orange;
  font-size: 1.5em;
}

.origin-destination-next {
  font-weight: 300;
  font-size: 0.9em;
}

.info-circle {
  margin-right: 10px;
}

.accept-wrapper {
  background-color: var(--darker-blue);
  position: absolute;
  width: 100%;
  bottom: 0px;
  padding: 15px 10px;
}

@media only screen and (min-width: 415px) {
  .accept-wrapper {
    position: relative;
    margin-top: 30px;
  }
}

.accept-header-wrapper {
  padding: 5px 0 20px 0;
  font-size: 1.3em;
  margin-bottom: 10px;
}

.accept-header {
  display: inline-block;
  color: white;
  float: left;
  font-size: 0.825em;
}

.disclaimer-tickettypes {
  margin-top: 10px;
  margin-bottom: 10px;
}

.disclaimer-tickettypes span{
  margin-right: 5px;
}

.disclaimer {
  color: white;
  font-size: .825em;
  font-weight: 300;
  text-align: left;
  margin: auto;
  width: 100%;
  height: 270px;
  overflow: auto;
}

::-webkit-scrollbar {
  display: none;
}

.disclaimer ul {
  list-style-position: outside;
  padding: 0 10px 0 15px;
}

.accept-button {
  margin: 10px auto;
  font-size: 1.125em;
  border: 1px solid black;
  border-radius: 5px;
  margin: 20px 30px 10px 30px;
  padding: 5px;
  color: var(--darker-blue);
  text-decoration: none;
  background-color: white;
}

.wallet-wrapper {
  background-color: var(--darker-blue);
  position: absolute;
  width: 100%;
  height: 100%;
}

@media only screen and (min-width: 415px) {
  .wallet-wrapper {
    max-height: 600px;
    max-width: 411px;
  }
}

.wallet-toggle-wrapper {
  padding-top: 15px;
  margin-bottom: 10px;
  border-radius: 10px;
  margin-left: 15px;
  margin-right: 15px;
}

.wallet-toggle-ticket {
  display: inline-block;
  width: 50%;
  color: #05377b;
  background-color: white;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.wallet-toggle-history {
  display: inline-block;
  width: 50%;
  color: white;
  background-color: var(--dark-blue);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.wallet-ticket-wrapper {
  margin: 15px;
  text-align: left;
  border-radius: 10px;
  height: 180px;
  background-color: white;
}

@media only screen and (min-width: 415px) {
  .wallet-ticket-wrapper {
    max-width: 380px;
  }
}

.wallet-ticket-top {
  color: black;
  border-bottom: 1px solid gray;
  margin-left: 15px;
  margin-right: 15px;
  height: 50%;
  padding-top: 20px;
}

.wallet-ticket-top > div:first-child{
  line-height: 2;
}

.wallet-ticket-bottom {
  color: black;
  height: 50%;
  margin-left: 15px;
  margin-right: 15px;
  padding-top: 10px;
}

.wallet-ticket-bottom > div:first-child{
  line-height: 2;
}

.wallet-ticket-bottom i {
  color: black;
  font-size: 18px;
  margin-right: 5px;
}

.cost-wrapper {
  background-color: var(--dark-blue);
  color: white;
  font-size: 1.1em;
  padding: 5px 15px;
}

.cost-total {
  display: inline-block;
  text-align: left;
  width: 50%;
}

.cost-price {
  display: inline-block;
  text-align: right;
  width: 50%;
}

.split-payments {
  padding: 15px;
  width: 100%;
  text-align: right;
  font-size: 1.1em;
}

.payment-method {
  padding: 5px 15px;
  width: 100%;
  background-color: var(--lighter-gray);
  text-align: left;
}

.payment-each {
  margin: 10px 15px;
  border-bottom: 1px solid var(--light-gray);
  padding: 20px 0;
  color: black;
  text-align: left;
}

.payment-icon {
  display: inline-block;
  background-color: var(--light-gray);
  width: 45px;
  height: 30px;
  margin-left: 15px;
  margin-right: 10px;
}

.payment-icon img {
  width: 45px;
}

.payment-toggle {
  padding-left: 10px;
}

.card-info-wrapper {
  padding-top: 175px;
}

.card-info-section {
  text-align: left;
  margin: 15px;
  font-size: .9em;
}

.security-code-input {
  width: 40%;
  margin: 10px 0;
  border: 1px solid #a7adba;
  background-color: #f2f2f2;
  height: 30px;
  padding: 10px
}

.email-input {
  width: 100%;
  margin: 10px 0;
  border: 1px solid #a7adba;
  background-color: #f2f2f2;
  height: 30px;
  padding: 10px
}

.card-info-instruction {
  margin: 10px 0;
}

.card-info-disclaimer {
  font-size: .76em;
  margin: 5px 0;
}

.accept-pay-button {
  margin: 10px auto;
  font-size: 1.2em;
  border-radius: 5px;
  margin: 20px 10px;
  padding: 10px;
  text-decoration: none;
  color: white;
  background-color: #3b5998;
}

.lock-icon {
  margin: 0 10px;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}
