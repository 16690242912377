.tap-button {
  font-size: 0.9em;
  font-weight: 700;
  /*border: 1px solid blue;*/
  margin-bottom: 5px;
}

.ticket-activated-at {
  background-color: #ed203e;
  font-size: 18px;
  border-radius: 5px;
  color: white;
  margin: 0 15px 5px 15px;
  padding: 7px;
  border-bottom: 2px solid #cc3333;
  font-weight: 600;
}

.ticket-type-info {
  background-color: white;
  font-size: 18px;
  border-bottom: 1px solid #a7adba;
  border-radius: 5px;
  color: black;
  margin: 10px 15px;
  padding: 7px;
  border-bottom: 2px solid light-gray;
  font-weight: 600;
  display: flex;
  justify-content: center;
}

.ticket-train-time {
  background-color: var(--darker-blue);
  width: 50%;
  border-bottom: 1px solid #a7adba;
  border-radius: 5px;
  color: white;
  padding: 10px;
}

.ticket-train-button {
  background-color: var(--darker-blue);
  width: 50%;
  border-bottom: 1px solid #a7adba;
  border-radius: 5px;
  color: white;
  padding: 15px;
  font-size: 1.3rem;
}

.lirr {
  background-color: white;
  font-size: 15px;
  margin: 0 15px;
  font-weight: 600;
  text-align: left;
  padding: 3px 5px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.ticket-area-code {
  /* background-color: #cdecf5; */
  /* background-image: url("./imgs/mta-01.png"); */
  background-color: #d9ebef;
  margin: 0 15px 15px 15px;
  border-bottom: 2px solid #a7adba;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.mtaLogo-bg {
  position: absolute;
  width: 100%;
  opacity: 0.2;
}

.wht-logo {
  opacity: 0.7;
}

.mtaLogo-bg img {
  width: 130px;
}

.ticket-area-code-wrapper1 {
  display: inline-block;
  text-align: right;
  vertical-align: top;
  width: 70%;
  height: 100%;
  padding: 0 10px 20px 10px;
  z-index: 2;
}

.ticket-area-code-wrapper2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  height: 100%;
}

.ticket-area-code-month {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
}

.ticket-area-code-month-wrapper {
  border: 2px solid black;
  padding: 5px 10px;
  font-size: 26px;
  font-weight: 800;
  z-index: 10;
}

.ticket-area-code-letter {
  line-height: 1.15;
  font-size: 26px;
  font-weight: 600;
}

.ticket-area-code-origindest {
  font-size: 1.8rem;
  font-weight: 800;
  line-height: 2.5;
}

.ticket-area-code-areanum {
  font-size: 2.9rem;
  font-weight: 800;
  padding-right: 10px;
}

.ticket-area-code-circle-top {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-bottom: 100px solid black;
  z-index: 10;
}

.ticket-area-code-circle-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-top: 100px solid black;
  z-index: 10;
}

.ticket-area-code-circle-inner-top {
  color: white;
  font-size: 30px;
  margin-top: 160px;
  font-weight: 600;
}

.ticket-area-code-circle-inner-bottom {
  color: white;
  font-size: 30px;
  margin-top: -160px;
  font-weight: 600;
}

.button-wrapper {
  background-color: blue;
}

.app-container-lower {
  background-color: #f1f1f1;
  height: 100%;
  /* height: 410px; */
  overflow: hidden;
  padding-bottom: 50px;
}
