.card-info-wrapper {
  padding-top: 175px;
}

.card-info-section {
  text-align: left;
  margin: 15px;
  font-size: .9em;
}

.security-code-input {
  width: 40%;
  margin: 10px 0;
  border: 1px solid #a7adba;
  background-color: #f2f2f2;
  height: 30px;
  padding: 10px
}

.email-input {
  width: 100%;
  margin: 10px 0;
  border: 1px solid #a7adba;
  background-color: #f2f2f2;
  height: 30px;
  padding: 10px
}

.card-info-instruction {
  margin: 10px 0;
}

.card-info-disclaimer {
  font-size: .76em;
  margin: 5px 0;
}

.accept-pay-button {
  margin: 10px auto;
  font-size: 1.2em;
  border-radius: 5px;
  margin: 20px 10px;
  padding: 10px;
  text-decoration: none;
  color: white;
  background-color: #3b5998;
}

.lock-icon {
  margin: 0 10px;
}
