.cost-wrapper {
  background-color: var(--dark-blue);
  color: white;
  font-size: 1.1em;
  padding: 5px 15px;
}

.cost-total {
  display: inline-block;
  text-align: left;
  width: 50%;
}

.cost-price {
  display: inline-block;
  text-align: right;
  width: 50%;
}

.split-payments {
  padding: 15px;
  width: 100%;
  text-align: right;
  font-size: 1.1em;
}

.payment-method {
  padding: 5px 15px;
  width: 100%;
  background-color: var(--lighter-gray);
  text-align: left;
}

.payment-each {
  margin: 10px 15px;
  border-bottom: 1px solid var(--light-gray);
  padding: 20px 0;
  color: black;
  text-align: left;
}

.payment-icon {
  display: inline-block;
  background-color: var(--light-gray);
  width: 45px;
  height: 30px;
  margin-left: 15px;
  margin-right: 10px;
}

.payment-icon img {
  width: 45px;
}

.payment-toggle {
  padding-left: 10px;
}
