.timeclock-main {
  margin: 5px 0;
  width: 100%;
  position: absolute;
  z-index: 2;
}

.timeclock-text {
  margin-top: 5vw;
  font-size: 8vw;
  color: white;
  text-shadow: 3px 3px 5px rgba(25, 25, 25, 1),
    -2px -2px 5px rgba(25, 25, 25, 1), 2px -2px 5px rgba(25, 25, 25, 1),
    -2px 2px 5px rgba(25, 25, 25, 1), 2px 2px 5px rgba(25, 25, 25, 1);
  position: relative;
  -webkit-animation-name: example;
          animation-name: example;
  -webkit-animation-duration: 8s;
          animation-duration: 8s;
  -webkit-animation-iteration-count: 100;
          animation-iteration-count: 100;
  max-width: 250px;
}

@media only screen and (min-width: 415px) {
  .timeclock-text {
    font-size: 2.2em;
    margin-top: 20px;
  }

  .timeclock-main {
    width: 400px;
    max-width: 400px;
  }
}

.timeclock-time {
  font-size: 25px;
  letter-spacing: 1px;
  line-height: 0%;
  padding: 0;
  white-space: nowrap;
}

.timeclock-date {
  line-height: 1;
  letter-spacing: 2px;
  margin: 0;
  padding: 0;
  font-size: 34px;
}

@-webkit-keyframes example {
  0% {
    left: 25px;
  }
  25% {
    left: 65px;
  }
  50% {
    left: 25px;
  }
  75% {
    left: 65px;
  }
  100% {
    left: 25px;
  }
}
