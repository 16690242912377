.buy-tickets-wrapper {
  padding-top: 70px;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: var(--darker-blue);
}

@media only screen and (min-width: 415px) {
  .buy-tickets-wrapper {
    max-height: 600px;
    max-width: 411px;
  }
}

.buytickets {
  background-color: var(--darker-blue);
}

.button {
  margin: 10px auto;
  font-size: 20px;
  border: 1px solid black;
  border-radius: 10px;
  margin: 10px;
  padding: 10px 5px;
  color: black;
  text-decoration: none;
  background-color: white;
}

.buy-ticket-button-blue {
  max-height: 74px;
  margin: 10px auto;
  font-size: 16px;
  border: 1px solid black;
  border-radius: 10px;
  margin: 10px;
  margin-top: -8px;
  padding: 10px 5px;
  color: white;
  text-decoration: none;
  background-color: var(--dark-blue);
}

.buy-ticket-button-blue img {
  vertical-align: bottom;
}

.buy-ticket-text {
  display: inline-block;
  text-align: left;
  width: 70%;
}

.buy-ticket-img {
  margin-right: 20px;
  display: inline-block;
  width: 10%;
}

.buy-ticket-img img{
  width: 50px;
}

.buy-ticket-arrow {
  width: 10%;
  color: #3b5998;
  display: inline-block;
  text-align: right;
  font-size: 1em;
}
