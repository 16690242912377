.accept-wrapper {
  background-color: var(--darker-blue);
  position: absolute;
  width: 100%;
  bottom: 0px;
  padding: 15px 10px;
}

@media only screen and (min-width: 415px) {
  .accept-wrapper {
    position: relative;
    margin-top: 30px;
  }
}

.accept-header-wrapper {
  padding: 5px 0 20px 0;
  font-size: 1.3em;
  margin-bottom: 10px;
}

.accept-header {
  display: inline-block;
  color: white;
  float: left;
  font-size: 0.825em;
}

.disclaimer-tickettypes {
  margin-top: 10px;
  margin-bottom: 10px;
}

.disclaimer-tickettypes span{
  margin-right: 5px;
}

.disclaimer {
  color: white;
  font-size: .825em;
  font-weight: 300;
  text-align: left;
  margin: auto;
  width: 100%;
  height: 270px;
  overflow: auto;
}

::-webkit-scrollbar {
  display: none;
}

.disclaimer ul {
  list-style-position: outside;
  padding: 0 10px 0 15px;
}

.accept-button {
  margin: 10px auto;
  font-size: 1.125em;
  border: 1px solid black;
  border-radius: 5px;
  margin: 20px 30px 10px 30px;
  padding: 5px;
  color: var(--darker-blue);
  text-decoration: none;
  background-color: white;
}
