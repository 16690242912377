:root {
  --darker-blue: #223365;
  --dark-blue: #445588;
  --blue: #9099b2;
  --gray: #adacac;
  --light-gray: #d6d6d6;
  --lighter-gray: #f8f6f6;
}

.App {
  text-align: center;
  margin: auto;
  width: 100%;
  max-width: 414px;
  background-color: white;
}

@media only screen and (min-width: 415px) {
  .App {
    border-top: 80px solid;
    border-right: 10px solid;
    border-bottom: 80px solid;
    border-left: 10px solid;
    border-radius: 50px;
    margin: 20px auto;
    height: 760px;
    max-width: 431px;
    overflow-y: auto;
  }

  ::-webkit-scrollbar {
    display: none;
  }
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}
