.test {
  background-color: white;
}

.header-container {
  position: fixed;
  width: 100%;
  max-width: 411px;
  z-index: 2;
}

@media only screen and (min-width: 415px) {
  .header-container {
    position: absolute;
  }
}

.location-header {
  height: 70px;
  background-color: var(--darker-blue);
  padding-top: 10px;
  color: white;
  border-bottom: 2px solid black;
}

.location-header-text {
  margin: auto;
  vertical-align: middle;
  font-size: 1.15em;
  font-weight: 600;
}

.location-header-arrow {
  color: white;
  position: absolute;
  margin: 10px;
}

.location-header-bars {
  color: white;
  position: absolute;
  margin: 10px;
  right: 10px;
  top: 5px;
}

.location-list-wrapper {
  padding-top: 132px;
}

.location-list-wrapper-destination {
  padding-top: 167px;
}

.location-list {
  width: 100%;
  text-align: left;
  font-size: .9em;
}

.location-list a {
  text-decoration: none;
  color: black;
}

.location-each {
  margin: 0 15px;
  border-bottom: 1px solid var(--light-gray);
  padding: 11px 0 11px 5px;
}

.search-wrapper {
  background-color: var(--darker-blue);
  padding: 6px;
}

.search-wrapper2 {
  border-radius: 10px;
  background-color: var(--dark-blue);
  margin: 5px;
  text-align: left;
}

.location-search-icon {
  margin: 0 10px 0 10px;
}

.location-search-box {
  height: 35px;
  width: 80%;
  background-color: transparent;
  border: 0px solid;
  font-size: 15px;
  text-align: left;
  color: white;
}

.location-search-box:focus {
  outline: 1px solid var(--dark-blue);
}

.location-search-box::-webkit-input-placeholder {
  color: #8b9dc3 !important;
}

.alpha {
  background-color: var(--lighter-gray);
  color: var(--darker-blue);
  text-align: left;
}

.blank {
  background-color: var(--darker-blue);
  height: 30px;
}

.letter {
  margin: 0 10px;
  padding-top: 2px;
  font-size: 16px;
}

.black-bar {
  background-color: #011f4b;
  height: 6px;
}

.yellow-progress {
  height: 100%;
  background-color: orange;
}

.origin-destination {
  background-color: var(--darker-blue);
  color: white;
  text-align: left;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

.origin-destination-third {
  display: inline-block;
  vertical-align: middle;
  font-weight: 300;
  font-size: .9em;
}

.origin-destination-middle {
  padding: 0 10px;
}

.origin-destination-next {
  display: inline-block;
  margin-right: 5px;
}

#arrow-right {
  color: #3b5998;
}

.location-arrow {
  /*border: 1px solid red;*/
  float: right;
  color: orange;
  font-size: 1.5em;
}
