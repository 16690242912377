.time-block-wrapper {
  border-bottom: 2px solid #c0c5ce;
}

.colorblock-main {
  margin: 10px 0 0 0;
}

.each-block {
  background-color: var(--light-gray);
  width: 30%;
  padding-top: 12%;
  position: relative;
  display: inline-block;

  -webkit-animation-name: block;

          animation-name: block;
  -webkit-animation-duration: 15s;
          animation-duration: 15s;
  -webkit-animation-iteration-count: 100;
          animation-iteration-count: 100;
}

@-webkit-keyframes block {
  0% {
    opacity: 1;
  }
  5% {
    opacity: 0.7;
  }
  10% {
    opacity: 1;
  }
  15% {
    opacity: 0.7;
  }
  20% {
    opacity: 1;
  }
  25% {
    opacity: 0.7;
  }
  30% {
    opacity: 1;
  }
  35% {
    opacity: 0.7;
  }
  40% {
    opacity: 1;
  }
  45% {
    opacity: 0.7;
  }
  50% {
    opacity: 1;
  }
  55% {
    opacity: 0.7;
  }
  60% {
    opacity: 1;
  }
  65% {
    opacity: 0.7;
  }
  70% {
    opacity: 1;
  }
  75% {
    opacity: 0.7;
  }
  80% {
    opacity: 1;
  }
  85% {
    opacity: 0.7;
  }
  90% {
    opacity: 1;
  }
  95% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

.block1 {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  /* opacity: 0.5; */
}

.block3 {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.sketch-picker-wrapper {
  /* height: 0px;
  position: fixed;
  top: -80px */
  height: 0;
}

.sketch-picker-box {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.sketch-picker {
  z-index: 10;
  position: relative;
  width: 100% !important;
}
