.half-circle {
  background-color: var(--dark-blue);
  width: 80px;
  height: 40px;
  border-bottom-left-radius: 60px;
  border-bottom-right-radius: 60px;
  margin: auto;
}

.fa {
  color: white;
  font-size: 40px;
}
