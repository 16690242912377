.tab-wrapper {
  background-color: var(--dark-blue);
}

.single-tab {
  display: inline-block;
  padding: 10px;
  width: 50%;
  background-color: white;
  margin-top: 5px;
  border-top-right-radius: 8px;
  font-size: .9em;
}

.multiple-tab {
  display: inline-block;
  padding: 10px;
  width: 50%;
  color: white;
  font-weight: 300;
  font-size: .9em;
}

.ticket-list-wrapper {
  background-color: white;
  padding-top: 185px;
}

.ticket-list {
  width: 100%;
  text-align: left;
  background-color: white;
}

.ticket-each {
  margin: 0 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--light-gray);
  padding: 20px 0;
  color: black;
}

.ticket-type {
  display: inline-block;
  width: 70%;
  text-align: left;
}

.ticket-price {
  display: inline-block;
  width: 30%;
  text-align: right;
}

.ticket-arrow {
  padding: 0 10px;
  color: orange;
  font-size: 1.5em;
}

.origin-destination-next {
  font-weight: 300;
  font-size: 0.9em;
}

.info-circle {
  margin-right: 10px;
}
